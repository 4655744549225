body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.font-britannic-bold {
  font-family: "Britannic Bold";
}

.font-arial {
  font-family: "Arial";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url(https://db.onlinewebfonts.com/c/8be4a2f403c2dc27187d892cca388e24?family=Britannic+Bold); 

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .bgimg {
    @apply bg-no-repeat bg-cover bg-[url('/src/images/bg.jpg')];
  }

  .logobg {
    @apply bg-no-repeat bg-contain bg-transparent bg-center bg-[url('/src/images/logo.jpeg')];
  }

  .inputstyle {
    @apply rounded-md p-1 ring-1 bg-gradient-to-br from-purple-500/20 ring-purple-500;
  }
  .specialinputstyle {
    @apply rounded-md p-1 ring-1 bg-gradient-to-br from-pink-500/20 ring-purple-500;
  }
  .selectedinputstyle {
    @apply rounded-md p-1 ring-1 bg-gradient-to-br from-pink-500 ring-purple-500;
  }

  .gradient1 {
    @apply bg-gradient-to-br from-white via-blue-50 to-white;
  }

  .shadowstyle-1 {
    @apply shadow-[1px_1px_5px_rgba(00,00,05,1),-3px_-3px_6px_rgba(255,255,255,1)] p-2 rounded-md;
  }

  .revised-shadow {
    @apply shadow-[4px_4px_4px_rgba(2,2,2,1),-4px_-4px_4px_rgba(200,200,200,1)]
  }

  .error-msg {
    @apply text-red-500 font-light;
  }

  .default-container {
    @apply rounded-lg ring-purple-600 m-2 p-2 bg-purple-100 ring-1;
  }
}